/*---------------------------------------------------------------------*/
/* Global Styles
/*---------------------------------------------------------------------*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.inventory-height {
  height: calc(100vh - 150px);
}

.customWidth {
  width: 100% !important;
}
.radio-btn-social {
  width: 12px;
  height: 12px;
  background-color: transparent !important;
}
.radio-btn-social:checked {
  border: 0.5px solid #5046e4;
  background-color: transparent !important;
  background-image: url('data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%235046E4%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%273%27/%3e%3c/svg%3e');
}
.radio-btn-social:focus {
  border: 0.5px solid #5046e4 !important;
  --tw-ring-color: transparent !important;
  --tw-ring-offset-shadow: transparent !important;
}
